/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import SimpleImageSlider from "react-simple-image-slider";
import ReactAudioPlayer from 'react-audio-player';
import logo from './imgs/heart3.png';
import yjw from './imgs/hrd.jpg';
import m1 from './imgs/1.png'
import m2 from './imgs/2.png'
import m3 from './imgs/3.png'
import m4 from './imgs/5.png'
import m5 from './imgs/6.png'
import m6 from './imgs/10.png'
import m7 from './imgs/11.png'
import $ from 'jquery';
import './App.css';
import  './sakura.js';


function App() {
  function delay(milliseconds){
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
  }
 
  const song= useRef(null);
  const onPlay = () => {
   textHandle();
   $("#player").hide();
  }
  const textHandle = () => {
    
    var i = 0
    
    function showText() {
      var ele = document.getElementById('demo');
      var title = `郝若狄: 距离我们分开也没有几天啦。在这最后的几天里，我给你写了一个小网站。 不知道是不是我太喜欢你啦，我老是想起我们刚开始认识，在一起的日子，总是觉得很开心很开心。
      我知道你有时候忍受不了我的脾气，但是我其实也为你改了很多。 我知道你有时候也怀疑我对你的感情， 你觉得你似乎对我来说是附属品是代替品，但其实从一开始我就没有这种想法， 
      只是有时候我可能没有好好的表达自己，让你误解了， 其实有时候我只是太在意你和你对我的回应， 有时候就会耍点小脾气。 我也知道你觉得我好像几乎每次见你都要和你睡觉，让你感觉不好，
      但其实我也不是有意的，只是每次见你我就控制不住的想亲亲你抱抱你因为喜欢你。
      再过几天，你也要去悉尼开始新生活了，而我也不能确定我的将来会在哪里， 如果没缘分的话可能也不会再见了吧。 恋人总是会有分开的一天，在这最后几天里，
      我也希望我们可以更加重视当下的感受，不用太多去压抑自己的情感，不用太多去考虑以后，以后的事就交给缘分吧。 或许是没有了新鲜感，你对我也没有之前喜欢了， 不过还是想叫你宝贝，最后几天我也会很珍惜我们仅有的时间，我也希望你也能好好再爱我几天。
      就这几天在一起我们就好好爱对方。 好吗？   宝贝， 我爱你。 很爱很爱你。`;   
      var endNum = i + 1;
      if (i<=title.length) {
        ele.innerHTML = title.slice(0, endNum);
        setTimeout(showText, 250);
      }
      i++;
      if (i === title.length) {
        slideMemory()
      }
    }
    function showSlide(){
      $("#slide-container").show('3000');
      $("#app-container").css('opacity', '0.7');
      // document.getElementById('slide-container').style.display = 'block';
      // document.getElementById('slide-container').style.opacity = '0.3';
    }
    async function slideMemory(){
      var ele = document.getElementById('app-container');
      // ele.style.opacity = "0.1";
      await delay(3000);
      document.getElementById('demo').style.display = 'none';
      showSlide();
      console.log(ele);

    }
      showText();
  }
  const images = [
    { url: m1 },
    { url: m2 },
    { url: m3 },
    { url: m4 },
    { url: m5 },
    { url: m6 },
    { url: m7 },
  ];
  useEffect(() => {
    
  },[])
  return (
    <div className="App" id="app-container" >
      <div> <img style={{ position: 'fixed', top: "2%", left: '26%'}} src={logo} className="App-logo" alt="logo" />
        <img style={{ position: 'fixed', top: "2%"}} src={yjw} className="App-logo" alt="logo" />
        {/* <img style={{ position: 'fixed', top: '45%', left: '45%', height: '100px', width: '100px'}} src={play} alt="play-button" /> */}
        <iframe style={{ position: 'fixed', top: '45%', left: '45%', display: 'none'}}id="song" ref={song} className="who" width="50" height="50" src="https://www.youtube.com/embed/5vCdjTKZWDw?controls=0&amp;start=52" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        <div id="player" style={{ textAlign: 'center', position:'fixed;', top: '0;', opacity: '0.8', paddingTop: '15vh'}}>
        <ReactAudioPlayer
        src= './xyt.mp3'
        autoPlay='true'
        controls
        onPlay= { onPlay }
         />
      </div>
      <div className='title'>
        
       <div  id="demo" style={{ fontWeight: '700', width: '200px', height: '700px', paddingTop: '10vh', overflow: 'hidden'}}> </div>
    
        </div>
        <div id="slide-container"  style={{ display: 'none', borderRadius: '12px', width: "340px", height: "340px", position: 'fixed', top: '50%', left: '50%', bottom: "0", marginLeft:'-170px',marginTop: '-170px', border: "4px solid black" }} >
      <SimpleImageSlider
        width={340}
        height={340}
        images={images}
        showBullets={true}
        showNavs={true}
        autoPlay={true}
        slideDuration={4}
        navSize={20}
        autoPlayDelay={15}
      />
    </div>
    </div>
  );
}

export default App;
